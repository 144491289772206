@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

@font-face {
  font-family: 'HKGrotesk-Medium';
  src: url('../assets/fonts/HKGrotesk-Medium.otf');
}
@font-face {
  font-family: 'HKGrotesk-Light';
  src: url('../assets/fonts/HKGrotesk-Light.otf');
}

body {
  background-color: #ffffff !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-weight: 500;
}

.hk-medium, p {
  font-family: 'HKGrotesk-Medium', sans-serif;
}

.hk-light {
  font-family: 'HKGrotesk-Light', sans-serif;
}

.questrial-regular{
  font-family: "Questrial", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.questrial-blue {
  color: #41A3E1 !important;
}

.hk-darkblue {
  color: #1A1449 !important;
}

.slick-list button {
  display: none;
}


@media screen and (max-width: 570px) {
  .slick-initialized .slick-slide {
    overflow-y: auto;
  }
}

.whatsapp-link {
  display: flex;
  width: 33px;
  height: 33px;
  background-color: #1A1449;
  color: #41A3E0;
  border-radius: 50%;
  font-size: 30px;
  justify-content: center;
  align-items: center;
}

.whatsapp-link svg {
  height: 25px;
}

.cursor-pointer {
  cursor: pointer;
}

.white-text {
  color: white;
}

.text-uppercase {
  text-transform: uppercase;
}

.btn-primary-light {
  background-color: #41A3E0 !important;
}

.default-logo, .white-logo {
  cursor: pointer;
}

.small-container {
  margin: 0 auto;
  max-width: 945px;
}
.accessibility-options {
  background-color: #1A1449 !important;
  right: 1vw !important;
  bottom: -20vh !important;
  z-index: 9147483647 !important;
}

div[vw].enabled {
  z-index: 9999 !important;
}

.accessibility-menu {
  transform: translateY(calc(20% - (-5px)));
}

.accessibility-options .fa-solid {
  color: #41A3E0;
}

@media screen and (min-width: 2200px) {
  .accessibility-menu {
    transform: translateY(calc(-70% - 60px));
  }
}