:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}


.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #35597e;
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.badge:not(.collapsed) {
  color: #fff;
  background-color:  #38536d;
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.link-whatsapp {
  padding: 30px 129px;
  position: absolute;
  right: 50px;
  top: 3px;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  /* border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); */
  border-bottom: 1px solid var(--bs-gray-600);
}
.accordion-item:first-of-type {
  /* border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius); */
}
.accordion-item:first-of-type .accordion-button {
  /* border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius); */
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  /* border-bottom-right-radius: var(--bs-accordion-border-radius); */
  /* border-bottom-left-radius: var(--bs-accordion-border-radius); */
}
.accordion-item:last-of-type .accordion-button.collapsed {
  /* border-bottom-right-radius: var(--bs-accordion-inner-border-radius); */
  /* border-bottom-left-radius: var(--bs-accordion-inner-border-radius); */
}
.accordion-item:last-of-type .accordion-collapse {
  /* border-bottom-right-radius: var(--bs-accordion-border-radius); */
  /* border-bottom-left-radius: var(--bs-accordion-border-radius); */
}
.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}
#arquivoDevSecopsJava,
#arquivodevCAnsi,
#arquivoDevAndroid,
#arquivoDevIos,
#arquivoDevFrontEnd,
#arquivoAnalistaTeste,
#arquivoEspecialistaInfraMainframe,
#arquivoEspecialistaProgramadorSAP,
#arquivoDesenvolvedoraCSharp,
#arquivoDevOpsCloud,
#arquivoTableau,
#arquivoFuncoesSAPGRC,
#arquivoDesenvolvedorPHP,
#arquivoGerentedeProjetos,
#arquivoconhecimentoemGRC,
#arquivoAnalistadeBI,
#arquivoScrumMaster,
#arquivoDesenvolvedoraSalesforces {
  display: none;
}
.arquivo {
  font-size: medium;
  /* width: 300px; */
  height: calc(1.9em + 1rem + 2px); 
  cursor: pointer;
  background: #eee;
  border: 1px solid #bbb;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 300ms ease-in-out, background 300ms ease-in-out;
  /* margin-left: 100px; */
}
.linkedin {
  display: flex;
  justify-content: center;
  /* margin-left: 50px; */
}
.arquivo:hover {
   background: #ccc;
   border: 1px solid #999;
}
.arquivo:focus {
  background: #ccc;
  border: 1px solid #999;
  box-shadow: 0 0 10px;
}
.btn-send {
  border: 1px solid #1e6565;
  background: #267272;
  color: #fff;
  width: 100%;
  height: 5em;
  border-radius: 50px;
  transition: color 300ms ease-in-out, background 300ms ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.btn-send:hover {
  color: #fff;
  background: #2e8c8c;
}
.btn-send:active{
  border-color: #1e6565;
  background: #2e8c8c;
}
.btn-send:focus {
  border-color: #1e6565;
  background: #2e8c8c;
}
.btn-send:disabled {
  opacity: .4;
  cursor: default;
}
.btn-send:disabled:hover {
  background: #2e8c8c;
}
.btn-shared {
  border: 1px solid #999;
  background: #ddd;
  color: #333;
  width: 100%;
  height: 5em;
  border-radius: 50px;
  transition: background 300ms ease-in-out;
  padding: 5px 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.btn-shared:hover {
  background: #bbb;
  border: 1px solid #888;
}
.btn-shared:active {
  border-color: #777;
  background: #bbb;
}

.career-page-wrapper .col-1,
.career-page-wrapper .col-2,
.career-page-wrapper .col-3,
.career-page-wrapper .col-4,
.career-page-wrapper .col-5,
.career-page-wrapper .col-6,
.career-page-wrapper .col-7,
.career-page-wrapper .col-8,
.career-page-wrapper .col-9,
.career-page-wrapper .col-10,
.career-page-wrapper .col-11,
.career-page-wrapper .col-12,
.career-page-wrapper .col,
.career-page-wrapper .col-auto,
.career-page-wrapper .col-sm-1,
.career-page-wrapper .col-sm-2,
.career-page-wrapper .col-sm-3,
.career-page-wrapper .col-sm-4,
.career-page-wrapper .col-sm-5,
.career-page-wrapper .col-sm-6,
.career-page-wrapper .col-sm-7,
.career-page-wrapper .col-sm-8,
.career-page-wrapper .col-sm-9,
.career-page-wrapper .col-sm-10,
.career-page-wrapper .col-sm-11,
.career-page-wrapper .col-sm-12,
.career-page-wrapper .col-sm,
.career-page-wrapper .col-sm-auto,
.career-page-wrapper .col-md-1,
.career-page-wrapper .col-md-2,
.career-page-wrapper .col-md-3,
.career-page-wrapper .col-md-4,
.career-page-wrapper .col-md-5,
.career-page-wrapper .col-md-6,
.career-page-wrapper .col-md-7,
.career-page-wrapper .col-md-8,
.career-page-wrapper .col-md-9,
.career-page-wrapper .col-md-10,
.career-page-wrapper .col-md-11,
.career-page-wrapper .col-md-12,
.career-page-wrapper .col-md,
.career-page-wrapper .col-md-auto,
.career-page-wrapper .col-lg-1,
.career-page-wrapper .col-lg-2,
.career-page-wrapper .col-lg-3,
.career-page-wrapper .col-lg-4,
.career-page-wrapper .col-lg-5,
.career-page-wrapper .col-lg-6,
.career-page-wrapper .col-lg-7,
.career-page-wrapper .col-lg-8,
.career-page-wrapper .col-lg-9,
.career-page-wrapper .col-lg-10,
.career-page-wrapper .col-lg-11,
.career-page-wrapper .col-lg-12,
.career-page-wrapper .col-lg,
.career-page-wrapper .col-lg-auto,
.career-page-wrapper .col-xl-1,
.career-page-wrapper .col-xl-2,
.career-page-wrapper .col-xl-3,
.career-page-wrapper .col-xl-4,
.career-page-wrapper .col-xl-5,
.career-page-wrapper .col-xl-6,
.career-page-wrapper .col-xl-7,
.career-page-wrapper .col-xl-8,
.career-page-wrapper .col-xl-9,
.career-page-wrapper .col-xl-10,
.career-page-wrapper .col-xl-11,
.career-page-wrapper .col-xl-12,
.career-page-wrapper .col-xl,
.career-page-wrapper .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}


#tamanhoExcedido {
  color: red;
  font-weight: 600;
}