.back-to-top {
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    width: 35px;
    height: 35px;
    background-color: #41a3e0;
    color: white;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    font-size: 20px;
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
    z-index: 10;
}

.back-to-top.visible {
    opacity: 1;
    visibility: visible;
}


@media screen and (min-width: 1024px) {
    .back-to-top {
        right: 10rem;
        bottom: 10rem;
    }
}