.second-section {
    text-align: center;
    background: linear-gradient(to bottom, #41A3E0 30%, #FFFFFF 70%);
}

.wave-studio {
  text-align: left;
}

.centered-button {
  display: block;
  margin: 0 auto;
}

.section-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 50px; 

    padding: 2rem 29%;
    gap: 1rem;
}

.section-wrapper h2 {
    color: #1a1449;
}

.section {
    flex: 1;
}

.flex-adjust {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.discovery-section {
    background-color: white;
    border-radius: 20px;
    padding: 15px;
}

.discovery-section button {
    padding: 2px 16px;
}

.discovery-section h2 {
    font-size: 28px;
}

.discovery-section p {
    text-align: justify;
}

.half-width {
    width: 68%;
}

.wave-studio-container p {
    width: 100%;
    text-align: justify;
}

.see-more {
    background-color: white;
    border: none;
    border-radius: 5px;
    padding: 2px 8px;
    color: #41a3e0;
}

.know-button {
    background-color: #201046ff;
    border-radius: 5px;
    padding: 4px 10px;
    color: white;
}

.second-section .know-button {
    border-radius: 20px;
    border: none;
    padding: 7px 15px;
}

.second-section .discovery-section p {
    width: 261px;
}

@media screen and (max-width: 767px) {
    .second-section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .second-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 4rem;
    }
    
    .wave-studio {
        flex: 1;
        margin-right: 2rem;
    }

    .section-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        gap: 2rem;
        padding: 0;
    }

    .section {
        border-radius: 10px;
    }

    .discovery-section h2 {
        color: #333;
    }

    .discovery-section p {
        color: #666;
    }

    .discovery-section button {
        background-color: #201046ff;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 8px 16px;
        cursor: pointer;
    }

    .discovery-section button:hover {
        background-color: #1a0733ff;
    }

    .wave-studio-container {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .wave-studio-container p {
        width: 100%;
    }

    .wave-studio-container img {
        max-width: 100%;
        height: auto;
        margin-left: 2rem;
        position: relative;
        right: 1rem;
        top: -1rem;
    }

}

@media screen and (min-width: 768px) {
    .section-wrapper {
        flex-wrap: wrap;
    }
}

@media screen and (min-width: 1024px) {
    .section-wrapper {
        flex-wrap: nowrap
    }
}