.webkit-fill {
    width: -webkit-fill-available;
}

.blue-text {
    color: #41aee0;
}

.dark-title {
    color: #1A1449;
    font-size: 28px;
}

.pt-7 {
    padding-top: 7rem;
}

.gap-0 {
    gap: unset;
}

.pd-0 {
    padding: unset;
}

.center-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-align-center {
    text-align: center;
}

.background-blue {
    background-color: #41A3E0;
}

@media screen and (max-width: 767px) {
    .homepage-section {
      flex-direction: column;
      height: auto;
      padding: 3rem 0rem;
    }

    .section-width {
        max-width: unset;
    }
}