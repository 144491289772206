.fourth-section .block {
    width: 10rem;
    height: 10rem;
}

.fourth-section {
    text-align: left;
    overflow: hidden;
    position: relative;
    margin-top: 5rem;
}
  
.fourth-section .row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 3rem 0;
  position: relative;
}

.fourth-section .white-space {
    white-space: nowrap;
  }

  .fourth-section  .client-image {
    width: 11rem;
    transition: transform 0.3s;
  }
  
  .fourth-section .row img {
    flex-shrink: 0;
  }
  
  .fourth-section  .slide-right {
    animation: slideRight 30s linear infinite;
  }
  
  .fourth-section  .slide-left {
    animation: slideLeft 30s linear infinite;
  }
  
  .fourth-section  .paused {
    animation-play-state: paused;
  }
  
  @keyframes slideRight {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  @keyframes slideLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  